import { For, Suspense, useContext, createMemo } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { TextLink } from '@troon/ui';
import { OldCardCtx } from '../../../../../providers/card';
import { Content } from '../../../../../components/content';
import { gql } from '../../../../../graphql';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { FacilityCard } from '../../../../../components/facility/card';
import { PageHeader } from '../../../../../components/page-header';
import type { FacilityCardFragment } from '../../../../../graphql';

export default function CardStateCourses() {
	const params = useParams<{ groupId: string; stateId: string }>();
	const card = useContext(OldCardCtx);
	const facilities = createAsync(() => getFacilities({ stateId: params.stateId, cardGroupId: params.groupId }), {
		deferStream: true,
	});

	const state = createMemo(() => card()?.card.states.find((state) => state.id === params.stateId));

	return (
		<>
			<Title>
				{card()?.card.group.name} courses in {state()?.name} | Troon Rewards
			</Title>

			<Content>
				<div class="flex flex-col gap-8">
					<PageHeader
						title={
							<div class="flex flex-col gap-4">
								<TextLink href={`/card/${card()?.card.group.id}`} class="block text-base font-normal">
									{card()?.card.group.name}
								</TextLink>{' '}
								Courses in {state()?.name}
							</div>
						}
						subtext={
							<p>
								Explore and book tee times at courses in {state()?.name} that are included with the{' '}
								{card()?.card.group.name}.
							</p>
						}
					/>

					<Suspense>
						<h2 class="text-xl font-semibold">
							{facilities()?.facilities.length} course{facilities()?.facilities.length !== 1 ? 's' : ''}
						</h2>
						<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
							<For each={facilities()?.facilities as Array<FacilityCardFragment>}>
								{(facility, index) => (
									<li>
										<FacilityCard
											facility={facility}
											level="h3"
											loading={index() <= 15 ? 'eager' : 'lazy'}
											disableFavorites
										/>
									</li>
								)}
							</For>
						</ul>
					</Suspense>
				</div>
			</Content>
		</>
	);
}

const facilityQuery = gql(`
query cardRegionFacilities($stateId: String!, $cardGroupId: String!) {
  facilities(stateId: $stateId cardGroupId: $cardGroupId) {
			...FacilityCard
  }
}`);

const getFacilities = cachedQuery(facilityQuery);
